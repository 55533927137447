
import { defineComponent } from "vue-demi";
import LinkMain from "@/components/profile/link/index.vue";

export default defineComponent({
  name: "LinkView",
  components: {
    LinkMain,
  },
});
